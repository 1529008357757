import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "proposal-client"

export default {
    actions: {
        loadProposal(context, hash) {
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${hash}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((error) => {
                    return reject(error)
                })
            })
        }
    }
}