import {createRouter, createWebHistory} from 'vue-router'
import MainComponent from "@/components/main/MainComponent";

import HomeView from '@/views/HomeView.vue'
import BudgetView from "@/views/budget/BudgetView";
import ProposalView from "@/views/proposal/ProposalView";
import SpotView from "@/views/spot/SpotView";
import MenuView from "@/views/menu/MenuView";

const routes = [
    {
        path: '/',
        component: MainComponent,
        children: [
            {
                path: '/',
                name: 'home',
                component: HomeView
            },
        ]
    },

    {
        path: '/private',
        component: MainComponent,
        children: [
            {
                path: 'budget/:hash',
                name: 'budget',
                props: true,
                component: BudgetView
            },
        ]
    },

    {
        path: '/private',
        component: MainComponent,
        children: [
            {
                path: 'proposal/:hash',
                name: 'proposal',
                props: true,
                component: ProposalView
            },
        ]
    },

    {
        path: '/pontos',
        component: MainComponent,
        children: [
            {
                path: 'midia-indoor-painel-led-elevador-limeira-sp',
                name: 'pontos',
                props: true,
                component: SpotView
            },
        ]
    },

    {
        path: '/',
        component: MainComponent,
        children: [
            {
                path: 'cardapio-frankfurt.pdf',
                name: 'cardapio-frankfurt',
                props: true,
                component: MenuView
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
