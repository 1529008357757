<template>
    <div class="body">
        <div class="proposal-template">
            <header>
                <div class="logo">
                    <img src="@/assets/images/d7m-logo-180x80px.png" alt="">
                </div>
                <div class="social">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/d7mmarketing/">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>

                        <li>
                            <a target="_blank" href="https://www.instagram.com/d7mmarketing/">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div class="capa">
                <img src="@/assets/images/capa.png" alt="">
            </div>

            <footer>
                <div class="div-footer">
                    <h1>Proposta</h1>
                    <br>
                    <h5>Data: {{ formData.dateBR }}</h5>
                    <h5>Válido por {{ formData.valid }} dias.</h5>
                    <h5>Versão {{ formData.version }}.</h5>
                </div>

                <div class="div-footer-two">
                    <span>Código:</span>
                    <p>{{ formData.code }}</p>
                </div>

                <div class="contact-footer">
                    <span>Contato:</span>
                    <a target="_blank"
                       href="https://ul.waze.com/ul?place=ChIJQ3BEevCAyJQRPAH610ZICYw&ll=-22.57111030%2C-47.40192990&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                        <div class="icon-email">
                            <img src="@/assets/images/waze-icon.svg" alt="">
                            R Prefeito Marciliano, 217 <br> Vl. Cidade Jardim, Limeira - SP
                        </div>
                    </a>

                    <a target="_blank" href="mailto:contato@d7m.com.br">
                        <div class="icon-email">
                            <img src="@/assets/images/email-icon.svg" alt="">
                            contato@d7m.com.br
                        </div>
                    </a>

                    <a target="_blank" href="https://wa.me/551930348991">
                        <div class="icon-whatsapp">
                            <img src="@/assets/images/whatsapp-icon.svg" alt="">
                            (19) 3034-8991
                        </div>
                    </a>
                </div>
            </footer>
            <hr>
            <section>
                <div class="text-section">
                    <h4><strong>{{ formData.name }}</strong></h4>
                    <h4><strong>Cliente:</strong> {{ formData.client_name }}</h4>
                    <h4><strong>A/C:</strong> {{ formData.responsible }}</h4>
                </div>

                <div class="container-section" v-for="(data, index) in formData.sections" :key="index">
                    <div class="section">
                        <h1><strong>{{ data.nameSection }}</strong></h1>
                        <li v-for="(data, indexTwo) in data.itens" :key="indexTwo">
                            {{ data.item }}
                        </li>
                    </div>
                </div>
                <p v-if="formData.obs"><strong>Obs.:</strong>{{ formData.obs }}</p>
            </section>

            <div class="rodape-data">
                {{ formData.datePT }}
                <div class="cronometro">
                    <div class="tempo">
                        <a :href="formData.linkPdf" target="_blank">
                            <img src="@/assets/images/print-icon.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProposalView",

    props: {
        hash: {
            required: true
        }
    },

    created() {
        this.loadItems()
    },

    data() {
        return {
            formData: {
                client_name: '',
                code: '',
                linkPdf: '',
                dateBR: '',
                datePT: '',
                name: '',
                obs: '',
                responsible: '',
                sections: [],
                valid: '',
                version: ''
            }
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadProposal', this.hash)
                .then((r) => {
                    this.formData = r
                })
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.body {
    width: 100vw;
    color: #000000;
    padding: 0 25px;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    font-family: 'Comfortaa', cursive;
    background: #000000 url(@/assets/images/bg-lines.png) center;
}

.proposal-template {
    top: 0;
    left: 25%;
    z-index: 5;
    width: 1000px;
    margin: 30px 0;
    position: absolute;
    background-color: #fdfdfd;
}

header {
    height: 20%;
    width: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 30%;
    height: 100%;
    text-align: left;
}

.logo img {
    width: 45%;
    height: auto;
}

.social ul {
    display: flex;
    list-style: none;
}

.social ul li a {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    display: flex;
    color: #000000;
    font-size: 20px;
    border-radius: 50%;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border: 2px solid #0e0e0e;
    transition: all ease 0.3s;
}

.social ul li a:hover {
    color: #fdfdfd;
    cursor: pointer;
    background: #d67c1c;
    border-color: #d67c1c;
}

.capa,
.capa img {
    width: 100%;
    height: auto;
    display: block;
}

footer {
    height: 20%;
    width: 100%;
    display: flex;
    text-align: left;
    position: relative;
    padding: 30px 15px 0;
    justify-content: space-between;
}

.div-footer h1 {
    color: #000000;
}

.div-footer h5 {
    font-size: 16px;
    margin-bottom: 5px;
}

.contact-footer span,
.div-footer-two span {
    color: #d67c1c;
    font-weight: bold;
    text-transform: uppercase;
}

.div-footer-two p,
.contact-footer a {
    color: #000;
    padding: 3px 5px;
    text-decoration: none;
}

.icon-email img,
.icon-whatsapp img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

section {
    width: 100%;
    margin: 20px 0;
    padding: 0 15px;
}

.text-section {
    margin-bottom: 20px;
    text-align: justify;
}

.text-section h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.container-section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 30px;
    justify-content: flex-start;
    border-bottom: 2px solid rgb(226, 226, 226);
}

.container-section h1 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0.5em;
}

.section {
    width: 100%;
    position: relative;
}

section li {
    font-size: 14px;
    margin-left: 1em;
    text-align: justify;
    margin-bottom: 0.5em;
}

.section li::marker {
    color: #d67c1c;
}

.rodape-data {
    display: flex;
    padding: 10px;
    height: auto;
    width: 100%;
    background-color: #d67c1c;
    font-size: 1.5rem;
    color: #fdfdfd;
    align-items: center;
    justify-content: space-between;
}

.cronometro {
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tempo {
    width: 65px;
    height: 65px;
    display: grid;
    border-radius: 50%;
    place-items: center;
    border: 2px solid white;
}

.tempo a > img {
    height: 35px;
    filter: invert(98%) sepia(13%) saturate(91%) hue-rotate(243deg) brightness(121%) contrast(100%);
}

@media (max-width: 991px) {
    .body {
        padding: 0;
        width: 100%;
    }

    .body::after,
    .body::before {
        display: none;
    }

    hr {
        display: none;
    }

    section {
        display: flex;
        margin-top: 95px;
        align-items: center;
        flex-direction: column;
    }

    .proposal-template {
        margin: 0;
        width: 100%;
        position: unset;
    }

    .logo {
        height: 50%;
        width: 100%;
        padding: 5px;
        position: unset;
        text-align: left;
    }

    .logo img {
        width: 50%;
        height: auto;
        object-fit: cover;
    }

    footer {
        padding: 5px 15px;
        font-size: 0.7rem;
        flex-direction: column;
        justify-content: space-between;
    }

    .contact-footer {
        right: 0;
        position: absolute;
        padding: 5px 15px;
    }

    .div-footer-two {
        left: 0;
        top: 90px;
        padding-left: 15px;
        position: absolute;
    }

    .section {
        margin-left: 20px;
        font-size: 0.7rem;
    }

    .container-section {
        width: 95%;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid rgb(226, 226, 226);
    }

    .container-section img {
        width: 30px;
    }

    .text-section {
        width: 95%;
        margin-top: 25px;
        font-size: 0.7rem;
    }

    .rodape-data {
        font-size: 1rem;
    }

    .tempo {
        width: 45px;
        height: 45px;
    }

    .tempo a > img {
        height: 25px;
    }
}

@media (max-width: 820px) {
    footer {
        height: 15%;
        padding-top: 25px;
    }
}

@media (max-width: 428px) {
    section {
        padding: 0;
    }

    section li {
        font-size: 14px;
        margin-left: 0;
        text-align: justify;
        margin-bottom: 0.7em;
    }

    .section {
        margin-left: 0;
    }

    .div-footer-two {
        top: 185px;
    }

    .rodape-data {
        height: 75px;
    }

    .cronometro {
        height: auto;
    }

    .social ul li a {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}
</style>
