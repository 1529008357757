import {createStore} from 'vuex'

import Budget from "@/store/budget";
import Proposal from "@/store/proposal";
import Spot from "@/store/spot";

export default createStore({
    modules: {
        budget: Budget,
        proposal: Proposal,
        spot: Spot
    }
})
