<template>
    <div class="body">
        <div class="bg-glitch">
            <div class="wrapper">
                <ul>
                    <a href="https://www.facebook.com/d7mmarketing" target="_blank" title="facebook">
                        <li class="facebook"><i class="fa-brands fa-facebook-f fa-lg" aria-hidden="true"></i></li>
                    </a>
                    <a href="https://www.instagram.com/d7mmarketing" target="_blank" title="instagram">
                        <li class="instagram"><i class="fa-brands fa-instagram fa-lg" aria-hidden="true"></i></li>
                    </a>
                    <a href="https://wa.me/551930348991" target="_blank" title="whatsApp">
                        <li class="whatsapp"><i class="fa-brands fa-whatsapp fa-lg" aria-hidden="true"></i></li>
                    </a>
                </ul>
            </div>

            <div class="logo">
                <img src="@/assets/images/d7m-marketing-logo.png" alt="">
            </div>

            <div class="container-pag">
                <h1>Site em <br> <span>Manutenção</span></h1>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

.body {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    background-color: #0e0e0e;
}

.logo {
    padding-top: 20px;
    width: 100%;
    height: 80px;
    text-align: center;
}

.logo img {
    height: 100%;
}

.bg-glitch {
    height: 100%;
    width: 100%;
    background: url(@/assets/images/background-desktop.jpg) no-repeat center;
    background-size: cover;
}

.bg-glitch::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/bg-lines.png) center;
    z-index: 1;
}

.bg-glitch::after {
    content: "";
    width: 100%;
    height: 100%;
    background: url(@/assets/images/bg-noise.gif);
    opacity: 0.04;
    position: absolute;
    left: 0;
    top: 0;
}

@media (max-width: 991px) {
    .bg-glitch {
        background: url(@/assets/images/background-mobile.jpg) no-repeat center;
        background-size: cover;
    }
}

.container-pag {
    position: absolute;
    top: 45%;
    left: 30%;
    transform: translate(-50%, -50%)
}

.container-pag h1 {
    width: 100%;
    float: left;
    font-weight: 800;
    color: #f28f0c;
    font-size: 8vw;
    line-height: 1;
}

.container-pag span {
    -webkit-text-stroke-width: 1px;
    transform: translateY(-40%);
    margin-left: 4%;
    display: flex;
    -webkit-text-stroke-color: #fff;
    color: transparent;
}

@media (max-width: 991px) {
    .container-pag {
        top: 30%;
        left: 50%;
    }
}

.wrapper {
    position: absolute;
    z-index: 3;
    bottom: 5%;
    left: 3%;
    width: 550px;
}

.wrapper ul {
    list-style: none;
}

.wrapper ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #D8E2DC;
    float: left;
    transition: all 0.5s ease;
}

.wrapper ul li .fa-brands {
    font-size: 25px;
    color: #D8E2DC;
    margin-top: 2px;
    transition: all 0.5s ease;
}

.wrapper ul li:hover.facebook {
    border: 5px solid #3b5998;
    box-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-facebook-f {
    color: #3b5998;
    text-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
}

/* instagram */
.wrapper ul li:hover.instagram {
    border: 5px solid #bc2a8d;
    box-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-instagram {
    color: #bc2a8d;
    text-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
}

/* whatsapp */
.wrapper ul li:hover.whatsapp {
    border: 5px solid #4dc247;
    box-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-whatsapp {
    color: #4dc247;
    text-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
}

/* media queries */
@media (max-width: 991px) {
    .wrapper {
        top: 40%;
        left: 6%;
    }

    .wrapper ul {
        display: flex;
        flex-direction: column;
    }

    .wrapper ul li {
        margin-top: 10px;
    }
}

@media screen and (max-width: 640px) {

    .container-pag h1 {
        font-size: 12vw;
    }


    .wrapper {
        width: 350px;
    }

    .wrapper ul li {
        height: 35px;
        width: 35px;
    }

    .wrapper ul li .fa {
        font-size: 15px;
    }

}

@media screen and (max-width: 340px) {
    .wrapper {
        width: 150px;
    }

    .wrapper ul li {
        margin: 15px;
    }
}

</style>
<script setup>
</script>