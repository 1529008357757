<template>
    <a :href="pdfPath" target="_blank" download="cardapio-frankfurt.pdf"></a>
</template>

<script>
export default {
    name: "MenuView",
    data() {
        return {
            pdfPath: process.env.BASE_URL + 'cardapio-frankfurt.pdf'
        }
    },

    methods: {
        // download() {
        //     let link = document.createElement('a');
        //     // link.href = "https://demo.d7mmarketing.com.br/frank/cardapio-frankfurt.pdf";
        //     link.href = "./cardapio-frankfurt.pdf";
        //     link.download = 'cardapio-frankfurt.pdf';
        //     link.dispatchEvent(new MouseEvent('click'));
        // }
    }
}
</script>