import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store'

import "@/assets/css/main.css"

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://system.d7m.com.br';
} else {
    axios.defaults.baseURL = 'https://system.d7m.com.br';
    // axios.defaults.baseURL = 'https://apidev.d7mmarketing.com.br';
}
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
