<template>
    <div class="body">
        <div class="spot-container">
            <header>
                <div class="logo">
                    <img src="@/assets/images/d7m-logo-180x80px.png" alt="">
                </div>
                <div class="social">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/d7mmarketing/">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>

                        <li>
                            <a target="_blank" href="https://www.instagram.com/d7mmarketing/">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div class="capa">
                <img src="@/assets/images/capa.png" alt="">
            </div>

            <div class="details">
                <div class="details-one ms-0 ms-lg-4">
                    <h1>Pontos de Mídia</h1>
                </div>

                <div class="details-contact">
                    <span>Contato:</span>
                    <a target="_blank"
                       href="https://ul.waze.com/ul?place=ChIJQ3BEevCAyJQRPAH610ZICYw&ll=-22.57111030%2C-47.40192990&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                        <div class="icon-email">
                            <img src="@/assets/images/waze-icon.svg" alt="">
                            R Prefeito Marciliano, 217 <br> Vl. Cidade Jardim, Limeira - SP
                        </div>
                    </a>

                    <a target="_blank" href="mailto:contato@d7m.com.br">
                        <div class="icon-email">
                            <img src="@/assets/images/email-icon.svg" alt="">
                            contato@d7m.com.br
                        </div>
                    </a>

                    <a target="_blank" href="https://wa.me/551930348991">
                        <div class="icon-whatsapp">
                            <img src="@/assets/images/whatsapp-icon.svg" alt="">
                            (19) 3034-8991
                        </div>
                    </a>
                </div>
            </div>
            <hr>

            <div class="spots">
                <div class="container">
                    <h2 class="mb-4 ms-0 mb-lg-4 ms-lg-4 text-start">
                        <span><i class="fa-solid fa-location-dot"></i> Pontos</span> <br> Painéis de LED
                    </h2>
                    <div class="row">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4"
                             v-for="(data, index) in formData.painel" :key="index">
                            <div class="d-flex">
                                <div class="content-spots">
                                    <h2>{{ data.name }}</h2>
                                    <strong>{{ data.title }}</strong>
                                    <address v-html="data.address"></address>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="indoor">
                <div class="container">
                    <h2 class="mb-4 ms-0 mb-lg-4 ms-lg-4 text-start">
                        <span><i class="fa-solid fa-location-dot"></i> Pontos</span> <br> Mídia Indoor
                    </h2>
                    <div class="row">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Churrascarias</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorchurrascaria" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Padarias</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorpadaria" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Academias</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indooracademia" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Lanchonete</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorlanchonetes" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Bar</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorbar" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4"
                             v-if="formData.indoorforun.length >= 1">
                            <div class="content-indoor text-start">
                                <h2>Fóruns</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorforun" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Pizzarias</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorpizzaria" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Conveniências</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorconveniencia" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Açougues</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indooracougue" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Hotel</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorhotel" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4">
                            <div class="content-indoor text-start">
                                <h2>Restaurantes</h2>
                                <div class="d-flex justify-content-between"
                                     v-for="(data, index) in formData.indoorrestaurante" :key="index">
                                    <p>{{ data.name }}</p>
                                    <p>{{ data.monitors_tvs }} TV's</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="elevators">
                <div class="container">
                    <h2 class="mb-4 ms-0 mb-lg-4 ms-lg-4 text-start">
                        <span><i class="fa-solid fa-location-dot"></i> Pontos</span> <br> Elevadores
                    </h2>
                    <div class="row">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 m-0 m-lg-4"
                             v-for="(data, index) in formData.elevador" :key="index">
                            <div class="d-flex">
                                <div class="content-elevators">
                                    <h2>{{ data.name }}</h2>
                                    <p>{{ data.title }}</p>
                                    <address v-html="data.address"></address>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="footer-data">
                {{ formData.dateExtesno }}
                <div class="cronometro">
                    <div class="tempo">
                        <a :href="formData.printPDF" target="_blank">
                            <img src="@/assets/images/print-icon.svg" alt="">
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "SpotView",

    created() {
        this.loadItems()
    },

    data() {
        return {
            formData: {
                indoorchurrascaria: [],
                indoorpadaria: [],
                indooracademia: [],
                indoorlanchonetes: [],
                indoorbar: [],
                indoorforun: [],
                indoorrestaurante: [],
                indoorpizzaria: [],
                indoorconveniencia: [],
                indooracougue: [],
                indoorhotel: [],
                painel: [],
                elevador: [],
                printPDF: '',
                dateExtesno: '',
            }
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadSpots')
                .then((r) => {
                    this.formData = r
                })
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.2/css/bootstrap.min.css');

h2 {
    font-weight: bold;
}

address {
    font-size: 14px;
    font-weight: normal;
}

.body {
    width: 100vw;
    padding: 0 25px;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    font-family: 'Comfortaa', cursive !important;
    background: #000000 url(@/assets/images/bg-lines.png) center;
}

.spot-container {
    top: 0;
    left: 25%;
    z-index: 5;
    width: 1000px;
    margin: 30px 0;
    position: absolute;
    background-color: #fdfdfd;
}

header {
    height: 20%;
    width: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 30%;
    height: 100%;
    text-align: left;
}

.logo img {
    width: 45%;
    height: auto;
}

.social ul {
    display: flex;
    list-style: none;
}

.social ul li a {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    display: flex;
    color: #000000;
    font-size: 20px;
    border-radius: 50%;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border: 2px solid #0e0e0e;
    transition: all ease 0.3s;
}

.social ul li a:hover {
    color: #fdfdfd;
    cursor: pointer;
    background: #d67c1c;
    border-color: #d67c1c;
}

.capa,
.capa img {
    width: 100%;
    height: auto;
    display: block;
}

.details {
    height: 20%;
    width: 100%;
    display: flex;
    text-align: left;
    position: relative;
    padding: 30px 15px 0;
    justify-content: space-between;
}

.details-one h1 {
    color: #000000;
}

.details-contact {
    display: block;
    color: #d67c1c;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.details-contact a {
    color: #000;
    padding: 3px 5px;
    font-weight: normal;
    text-decoration: none;
    text-transform: capitalize;
}

.icon-email img,
.icon-whatsapp img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

.spots,
.indoor,
.elevators {
    color: #000000;
    padding: 15px 0;
}

.spots h2 span,
.indoor h2 span,
.elevators h2 span {
    color: #d67c1c;
    font-size: 2.5rem;
}

.content-spots,
.content-elevators {
    width: 100%;
    text-align: left;
    font-weight: bold;
}

.content-spots h2,
.content-indoor h2,
.content-elevators h2 {
    color: #d67c1c;
    font-size: 1.5rem;
}

.content-spots strong {
    color: #000000;
    font-size: 1.5rem;
}

.content-indoor p {
    margin-bottom: 5px;
}

.content-elevators p {
    color: #000;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
}

.footer-data {
    display: flex;
    padding: 10px;
    height: auto;
    width: 100%;
    background-color: #d67c1c;
    font-size: 1.5rem;
    color: #fdfdfd;
    align-items: center;
    justify-content: space-between;
}

.cronometro {
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tempo {
    width: 65px;
    height: 65px;
    display: grid;
    border-radius: 50%;
    place-items: center;
    border: 2px solid white;
}

.tempo a > img {
    height: 35px;
    filter: invert(98%) sepia(13%) saturate(91%) hue-rotate(243deg) brightness(121%) contrast(100%);
}

@media (max-width: 991px) {
    hr {
        display: none;
    }

    .body {
        padding: 0;
        width: 100%;
    }

    .body::after,
    .body::before {
        display: none;
    }

    .spot-container {
        margin: 0;
        width: 100%;
        position: unset;
    }

    .logo {
        height: 50%;
        width: 100%;
        padding: 5px;
        position: unset;
        text-align: left;
    }

    .logo img {
        width: 50%;
        height: auto;
        object-fit: cover;
    }

    .social ul li a {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }

    .details-contact a {
        padding: 5px;
        font-size: 12px;
    }

    .footer-data {
        font-size: 1rem;
        padding: 0 10px;
    }

    .cronometro {
        height: 70px
    }

    .tempo {
        width: 45px;
        height: 45px;
    }

    .tempo a > img {
        height: 25px;
    }
}

@media (max-width: 428px) {
    .details-one h1 {
        width: 200px;
    }
}
</style>