<template>
    <div class="body">
        <div class="proposal-template">
            <header>
                <div class="logo">
                    <img src="@/assets/images/d7m-logo-180x80px.png" alt="">
                </div>
                <div class="social">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/d7mmarketing/">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/d7mmarketing/">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div class="capa">
                <img src="@/assets/images/capa.png" alt="">
            </div>

            <footer>
                <div class="div-footer">
                    <h1>Orçamento</h1>
                    <br>
                    <h5>Data: {{ formData.dateBR }}</h5>
                    <h5>Válido por {{ formData.valid }} dias.</h5>
                </div>

                <div class="div-footer-two">
                    <span>Código:</span>
                    <p>{{ formData.code }}</p>
                </div>

                <div class="contact-footer">
                    <span>Contato:</span>
                    <a target="_blank"
                       href="https://ul.waze.com/ul?place=ChIJQ3BEevCAyJQRPAH610ZICYw&ll=-22.57111030%2C-47.40192990&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                        <div class="icon-email">
                            <img src="@/assets/images/waze-icon.svg" alt="">
                            R Prefeito Marciliano, 217 <br> Vl. Cidade Jardim, Limeira - SP
                        </div>
                    </a>

                    <a target="_blank" href="mailto:contato@d7m.com.br">
                        <div class="icon-email">
                            <img src="@/assets/images/email-icon.svg" alt="">
                            contato@d7m.com.br
                        </div>
                    </a>

                    <a target="_blank" href="https://wa.me/551930348991">
                        <div class="icon-whatsapp">
                            <img src="@/assets/images/whatsapp-icon.svg" alt="">
                            (19) 3034-8991
                        </div>
                    </a>
                </div>
            </footer>
            <hr>
            <section>
                <div class="text-section">
                    <h4><strong>{{ formData.name }}</strong></h4>
                    <h4><strong>Cliente:</strong> {{ formData.client_name }}</h4>
                </div>

                <table>
                    <thead>
                    <tr>
                        <th scope="col" style="width: 35%">Serviço/Produto</th>
                        <th scope="col" style="width: 5%">Quantidade</th>
                        <th scope="col" style="width: 10%">Valor(UN)</th>
                        <th scope="col" style="width: 20%">Valor</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(data, index) in formData.itens" :key="index">
                        <td data-label="Serviço/Produto">{{ data.name }}</td>
                        <td data-label="Quantidade">{{ data.amount }}</td>
                        <td data-label="Valor(UN)">R$ {{ data.unit_price }}</td>
                        <td data-label="Valor">R$ {{ data.total_price }}</td>
                    </tr>
                    <tr>
                        <td class="text-right" data-label="Total" colspan="5">
                            Total de R$ {{ formData.totalBudget }}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div style="width: 100%; text-align: left; padding: 10px 0">
                    <strong>Obs.:</strong> {{ formData.obs }}
                </div>
            </section>

            <div class="rodape-data">
                {{ formData.datePT }}
                <div class="cronometro">
                    <div class="tempo">
                        <a :href="formData.linkPdf" target="_blank">
                            <img src="@/assets/images/print-icon.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "BudgetView",

    props: {
        hash: {
            required: true
        }
    },

    created() {
        this.loadItems()
    },

    data() {
        return {
            formData: {
                client_name: '',
                code: '',
                dateBR: '',
                datePT: '',
                linkPdf: '',
                name: '',
                obs: '',
                responsible: '',
                itens: [],
                valid: '',
                totalBudget: ''
            },
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadBudget', this.hash)
                .then((r) => {
                    this.formData = r
                    console.log(r)
                })
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

.body {
    width: 100vw;
    padding: 0 25px;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    font-family: 'Comfortaa', cursive !important;
    background: #000000 url(@/assets/images/bg-lines.png) center;
}

.proposal-template {
    top: 15px;
    left: 25%;
    z-index: 5;
    width: 1000px;
    margin: 30px 0;
    position: absolute;
    background-color: #fdfdfd;
}

p {
    padding: 30px 0;
    text-align: left;
}

hr {
    margin-top: 25px;
    margin-bottom: 25px;
}

.icon-email img,
.icon-whatsapp img {
    vertical-align: middle;
    height: 25px;
    width: 25px;
}

header {
    height: 20%;
    width: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 30%;
    height: 100%;
    text-align: left;
}

.logo img {
    width: 45%;
    height: auto;
}

footer {
    height: 20%;
    width: 100%;
    display: flex;
    text-align: left;
    position: relative;
    padding: 50px 15px 0;
    justify-content: space-between;
}

.div-footer h1 {
    color: #000000;
}

.div-footer h5 {
    color: #000000;
    font-size: 16px;
}

.contact-footer span,
.div-footer-two span {
    color: #d67c1c;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.div-footer-two p,
.contact-footer a {
    color: #000;
    padding: 3px 5px;
    text-decoration: none;
}

section {
    margin: 20px 0;
    width: 100%;
    padding: 0 15px;
}

section li {
    text-align: justify;
    font-size: 14px;
    margin-bottom: 0.2em;
    margin-left: 1em;
}

.text-section {
    margin-bottom: 20px;
    text-align: justify;
}

.text-section h1 {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.rodape-data {
    display: flex;
    padding: 10px;
    height: auto;
    width: 100%;
    background-color: #d67c1c;
    font-size: 1.5rem;
    color: #fdfdfd;
    align-items: center;
    justify-content: space-between;
}

/* ################################################################# */
.cronometro {
    height: 100px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tempo {
    display: grid;
    place-items: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 2px solid white;
}

.tempo a > img {
    height: 35px;
    filter: invert(98%) sepia(13%) saturate(91%) hue-rotate(243deg) brightness(121%) contrast(100%);
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 130, 150;
        stroke-dashoffset: 0;
    }
}

@media (max-width: 991px) {
    .body {
        padding: 0;
        width: 100%;
    }

    .body::after,
    .body::before {
        display: none;
    }

    header {
        padding: 0;
        position: relative;
    }

    hr {
        display: none;
    }

    section {
        margin-top: 95px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .proposal-template {
        position: unset;
        width: 100%;
        margin: 0;
    }

    .logo {
        padding: 15px;
        text-align: left;
        position: unset;
        height: 50%;
        width: 100%;
    }

    .logo img {
        object-fit: cover;
        height: auto;
        width: 30%;
    }

    footer {
        padding: 5px 15px;
        justify-content: space-between;
        flex-direction: column;
        font-size: 0.7rem;
    }

    .contact-footer {
        padding: 5px 15px;
        position: absolute;
        right: 0;
    }

    .div-footer-two {
        padding-left: 15px;
        position: absolute;
        top: 160px;
        left: 0;
    }

    .text-section {
        width: 95%;
        font-size: 0.7rem;
        margin-top: 25px;
    }

    .rodape-data {
        font-size: 1rem;
    }

    .tempo {
        height: 45px;
        width: 45px;
    }

    .tempo a > img {
        height: 25px;
    }
}

@media (max-width: 820px) {
    footer {
        padding-top: 25px;
        height: 15%;
    }
}

@media (max-width: 375px) {
    header {
        padding: 0 10px;
    }

    p {
        padding: 20px 0;
    }

    .logo {
        padding: 15px 0;
    }

    .logo img {
        width: 30%;
    }

    .text-header p {
        font-size: 14px;
    }
}

.text-right {
    text-align: right;
    font-size: 16px;
    font-weight: bold;
}

table {
    width: 100%;
}

table thead {
    background-color: #000000;
    color: #fff;
    font-size: 14px;
}

table thead th:first-child {
    padding: 10px;
    text-align: left;
}

table thead th {
    padding: 10px;
    text-align: left;
}

table thead:first-child {
    text-align: right;
}

table tbody td {
    font-size: 12px;
    border: 1px solid #000000;
    padding: 10px;
    text-align: left;
}

@media (max-width: 991px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 1px solid #000;
        display: block;
        margin-bottom: .625em;
    }

    table td {
        border-bottom: 1px solid #000;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }

    table tbody td {
        text-align: right;
    }
}

.capa {
    height: auto;
    width: 100%;
    display: block;
}

.capa img {
    width: 100%;
    height: auto;
    display: block;
}

.social {
    position: absolute;
    right: 2%;
}

.social ul {
    display: flex;
    list-style: none;
}

.social ul li a {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    display: flex;
    color: #000000;
    font-size: 20px;
    list-style: none;
    border-radius: 50%;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border: 2px solid #0e0e0e;
    transition: all ease 0.3s;
}

.social ul a:hover {
    color: #fdfdfd;
    background: #d67c1c;
    cursor: pointer;
    border-color: #d67c1c;
}

@media (max-width: 428px) {
    .rodape-data {
        height: 75px;
    }

    .cronometro {
        height: auto;
    }

    .social ul a {
        height: 40px;
        width: 40px;
        font-size: 16px;
    }
}
</style>